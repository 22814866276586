/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Badge,
  Button,
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import CircleSpinner from "../components/loaders/CircleSpinner";
import authService from "../services/auth.service";
import userService from "../services/user.service";
const BlogPosts = () => {
  const history = useHistory();
  const MAX_LENGTH = 100;
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let unmounted = false;
    setLoading(true);
    async function getPosts() {
      const body = await userService.getAuthorArticlesWithStatus("Published");
      if (!body.error)
        if (!unmounted) {
          if (body.data.all_author_articles) {
            setPosts(
              body.data.all_author_articles.map(post => ({
                id: post.slug,
                title: post.name,
                image: post.image,
                description: post.description || "",
                section: post.section.name,
                status: post.status
              }))
            );
          }
          setLoading(false);
        }
    }
    getPosts();
    return () => {
      unmounted = true;
    };
  }, []);

  const handleOnClick = id => {
    history.push(`/view-post/${id}`);
  };
  const handleEdit = id => {
    history.push(`/edit-article/${id}`);
  };
  const deletePost = postId => {
    async function DeletePost() {
      await userService.deletePost(postId);
      setPosts(
        posts.filter(post => {
          return post.id !== postId;
        })
      );
    }
    DeletePost();
  };

  return loading ? (
    <CircleSpinner />
  ) : (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title={"Welcome " + authService.getCurrentUser()}
          subtitle="Home"
          className="text-sm-left"
        />
      </Row>

      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Your Published Articles"
          subtitle=""
          className="text-sm-left"
        />
      </Row>
      <Row>
        {posts.length > 0 ? (
          posts.map((post, idx) => (
            <Col lg="6" sm="12" className="mb-4" key={idx}>
              <Card small className="card-post card-post--aside card-post--1">
                <div
                  className="card-post__image"
                  style={{ backgroundImage: `url('${post.image}')` }}
                >
                  <Badge
                    pill
                    className={`card-post__category bg-${post.categoryTheme}`}
                  >
                    {post.section}
                  </Badge>
                </div>
                <CardBody>
                  <h5 className="card-title">
                    <a
                      className="text-fiord-blue"
                      href={`/view-post/${post.id}`}
                    >
                      {post.title}
                    </a>
                  </h5>
                  <p className="card-text d-inline-block mb-3" dangerouslySetInnerHTML={{ __html: post.description.length > MAX_LENGTH
                      ? `${post.description.substring(0, MAX_LENGTH)}...`
                      : post.description} }>                    
                  </p>
                  <br />
                  <span className="text-muted">Status : {post.status}</span>
                  <br />
                  <Row className="d-flex px-3 border-0">
                    <br />
                    <Button
                      onClick={() => deletePost(post.id)}
                      theme="danger"
                      size="sm"
                      className="ml-auto mt-2"
                    >
                      <i className="material-icons">delete</i> Delete
                    </Button>
                    <Button
                      onClick={() => handleOnClick(post.id)}
                      theme="accent"
                      size="sm"
                      className="ml-2 mt-2"
                    >
                      <i className="material-icons">visibility</i> View
                    </Button>
                    <Button
                      onClick={() => handleEdit(post.id)}
                      theme="info"
                      size="sm"
                      className="ml-2 mt-2"
                    >
                      <i className="material-icons">edit</i> Edit
                    </Button>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          ))
        ) : (
          <h4 style={{ marginLeft: "20px" }}>
            You Have Not Written any Articles{" "}
          </h4>
        )}
      </Row>
    </Container>
  );
};

export default BlogPosts;
