import React from "react";
import styles from "./spinners.module.css";

const LoginLoader = () => {
    return (
      <div className={styles.parent}>
        <div className={styles.login}></div>
      </div>
    );
  }


export default LoginLoader;
