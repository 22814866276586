export default function authHeader({image = false}) {
  const user = JSON.parse(localStorage.getItem("user"));

  if (user && user.key) {
    if(image){
          return {
            Authorization: "Token " + user.key,
             "Content-Type": `multipart/form-data;boundary=----WebKitFormBoundaryyrV7KO0BoCBuDbTL`
          };
    }
    return { 
      Authorization: "Token " + user.key ,    
    };
  } else {
    return {};
  }
}
