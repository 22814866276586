import React from "react";
import { Container, Row, Col } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import UserDetails from "../components/user-profile-lite/UserDetails";
import UserAccountDetails from "../components/user-profile-lite/UserAccountDetails";
import authService from "../services/auth.service";
import Toast from "../components/toast/Toast"
const UserProfileLite = () => {
  const User = authService.getCurrentUserDetails();  
  return(
  <Container fluid className="main-content-container px-4">
  <Toast/>
    <Row noGutters className="page-header py-4">
      <PageTitle title="User Profile" subtitle="Overview" md="12" className="ml-sm-auto mr-sm-auto" />
    </Row>
    <Row>
      <Col lg="4">
        <UserDetails userDetails={User} />
      </Col>
      <Col lg="8">
        <UserAccountDetails  userDetails={User}/>
      </Col>
    </Row>
  </Container>
)
}

export default UserProfileLite;
