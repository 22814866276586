import React from "react";
import { useTable, useRowSelect } from "react-table";
import IndeterminateCheckbox from "./CheckBox";
import userService from "../../services/user.service";
import PageTitle from "../common/PageTitle";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button
} from "shards-react";
import { useHistory } from "react-router-dom";

function Table({ columns, data }) {
  const UserDetails = JSON.parse(localStorage.getItem("user_details"));
  const isSuperuser = UserDetails.user.is_superuser;
  const history = useHistory();
  const tableInstance = useTable(
    {
      columns,
      data
    },
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        // Let's make a column for selection
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          )
        },
        ...columns
      ]);
    }
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    selectedFlatRows
  } = tableInstance;
  const handlePublish = () => {
    const finalList = selectedFlatRows.map(d => d.original.id);
    userService
      .changeArticleStatus({
        ids: finalList,
        status: "Published"
      })
      .then(res => {
        tableInstance.toggleAllRowsSelected(false);
        history.go(0);
      });
  };

  const handleReject = () => {
    const finalList = selectedFlatRows.map(d => d.original.id);
    userService
      .changeArticleStatus({
        ids: finalList,
        status: "Rejected"
      })
      .then(res => {
        tableInstance.toggleAllRowsSelected(false);
        history.go(0);
      });
  };

  return (
    <Container fluid className="main-content-container px-4">
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Article Status"
          subtitle="View All Article Stats"
          className="text-sm-left"
        />
      </Row>
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0">All Articles</h6>
              {!isSuperuser ? (
                <></>
              ) : (
                <div>
                  <Button
                    onClick={handlePublish}
                    theme="info"
                    size="sm"
                    className="ml-2"
                  >
                    Publish
                  </Button>
                  <Button
                    onClick={handleReject}
                    theme="danger"
                    size="sm"
                    className="ml-2"
                  >
                    Reject
                  </Button>
                </div>
              )}
            </CardHeader>
            <CardBody className="p-0 pb-3">
              <table {...getTableProps()} className="table mb-0">
                <thead className="bg-light">
                  {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        <th
                          scope="col"
                          className="border-0"
                          {...column.getHeaderProps()}
                        >
                          {column.render("Header")}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  {rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map(cell => {
                          return (
                             <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Table;
