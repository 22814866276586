import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem
} from "shards-react";
import { ArticleContext } from "../../context/ArticleProvider";

const SidebarImageUpload = ({ title }) => {
  const { article, setArticle } = useContext(ArticleContext);

  const handleMainImageUpload = e => {
    setArticle({
      ...article,
      image: e.target.files[0]
    });
  };
  const handleBannerImageUpload = e => {
    setArticle({
      ...article,
      banner_image: e.target.files[0]
    });
  };
  return (
    <Card small className="mb-3">
      <CardHeader className="border-bottom">
        <h6 className="m-0">{title}</h6>
      </CardHeader>
      <CardBody className="p-0">
        <ListGroup flush>
          <ListGroupItem className="d-flex px-3">
            <div className="custom-file mb-3">
              <input
                type="file"
                className="custom-file-input"
                id="customFile2"
                accept="image/*"
                onChange={handleMainImageUpload}
              />
              <label className="custom-file-label" htmlFor="customFile2">
                {article.image
                  ? article.image.name
                  : "Choose Image"}
              </label>
            </div>
          </ListGroupItem>

          <ListGroupItem className="d-flex px-3">
            <div className="custom-file mb-3">
              <input
                type="file"
                className="custom-file-input"
                id="bannerImage"
                accept="image/*"
                onChange={handleBannerImageUpload}
              />
              <label className="custom-file-label" htmlFor="bannerImage">
                {article.banner_image
                  ? article.banner_image.name
                  : "Choose Banner Image"}
              </label>
            </div>
          </ListGroupItem>
        </ListGroup>
      </CardBody>
    </Card>
  );
};

SidebarImageUpload.propTypes = {
  title: PropTypes.string
};

SidebarImageUpload.defaultProps = {
  title: "Image For Article"
};

export default SidebarImageUpload;
