import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Card,
  Container,
  Row,
  CardBody,
  Form,
  FormInput,
  Button,
  ListGroupItem,
  FormSelect,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import CircleSpinner from "../components/loaders/CircleSpinner";
import Toast from "../components/toast/Toast";
import userService from "../services/user.service";

const SectionsEdit = () => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [sections, setSections] = useState([]);
  const [sectionId, setNewSectionId] = useState();
  const history = useHistory();

  useEffect(() => {
    let unmounted = false;
    async function getData() {
      const sectionList = await userService.getSectionList();
      if (!unmounted) {
        setSections(
          sectionList.data.map(({ name, id, slug }) => ({
            label: name,
            id: id,
            slug: slug
          }))
        );
        setNewSectionId(sectionList.data[0].slug);
      }
    }
    getData();
    return () => {
      unmounted = true;
    };
  }, []);

  const handleSubmit = e => {
    setLoading(true);
    e.preventDefault();
    if (name === "" || name === null) {
      setLoading(false);
      toast.error("Empty Name! Please fill the fields");
      return;
    }
    const data = { name: name };
    userService
      .updateSection(sectionId, data)
      .then(res => {
        setLoading(false);
        toast.success("Section Updated");
        setTimeout(() => {
          history.go(0);
        }, 2000);
      })
      .catch(err => {
        setLoading(false);
        toast.error("Some Error Occured");
      });
  };
  const handleValueChange = e => {
    setNewSectionId(e.target.value);
  };

  const deleteSection = e => {
    userService
      .deleteSection(sectionId)
      .then(res => {
        setLoading(false);
        toast.success("Section Deleted");
        setTimeout(() => {
          history.go(0);
        }, 2000);
      })
      .catch(err => {
        setLoading(false);
        toast.error("Some Error Occured");
      });
  };

  return (
    <div>
      <Container fluid className="main-content-container px-4">
        <Toast />
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="Edit Sections Here"
            subtitle="Update Sections"
            className="text-sm-left"
          />
        </Row>
        {loading ? (
          <CircleSpinner />
        ) : (
          <Card small className="mb-3">
            <CardBody>
              <ListGroupItem className="px-3 pb-2">
                <InputGroup className="mb-3">
                  <FormSelect value={sectionId} onChange={handleValueChange}>
                    {sections.map(({ label, id, slug }) => (
                      <option key={id} value={slug}>
                        {label}
                      </option>
                    ))}
                  </FormSelect>
                  <InputGroupAddon type="append">
                    <InputGroupText>Select Category</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </ListGroupItem>
              <Form className="add-new-post" onSubmit={handleSubmit}>
                <FormInput
                  size="lg"
                  className="mb-3"
                  placeholder="New Section Name"
                  value={name}
                  onChange={event => setName(event.target.value)}
                />
                <Button>Update Name</Button>
              </Form>
              <Button className="mt-4" theme="danger" onClick={deleteSection}>
                Delete
              </Button>
            </CardBody>
          </Card>
        )}
      </Container>
    </div>
  );
};

export default SectionsEdit;
