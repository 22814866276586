import React, { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";

import routes from "./routes";

import "bootstrap/dist/css/bootstrap.min.css";
import "./shards-dashboard/styles/shards-dashboards.1.1.0.min.css";
import Login from "./views/Login";
import { UserContext } from "./context/UserProvider";
const ChangePassword = React.lazy( import ('./views/ChangePassword'));
const ForgotPassword = React.lazy( import ('./views/ForgetPassword'));
const Errors = React.lazy( import ('./views/Errors'));

const App = ({hideLoader}) => {
  const { user } = useContext(UserContext);
  
  useEffect(() => {
    hideLoader();
  });

  return (
    <Router>
      <Switch>
        {routes.map((route, index) => {
          return (
            <Route
              key={index}
              exact
              path={route.path}
              component={props => {
                return user.auth ? (
                  <route.layout {...props}>
                    <route.component {...props} />
                  </route.layout>
                ) : (
                  <Redirect
                    to={{
                      pathname: `/login`,
                      state: { error: "Please Login to Access the Page" }
                    }}
                  />
                );
              }}
            />
          );
        })}
        <Route exact path="/login">
          <Login />
        </Route>
        <Route exact path="/forgot-password">
          <ForgotPassword />
        </Route>
        <Route exact path="/change-password">
          {user.auth ? (
            <ChangePassword />
          ) : (
            <Redirect
              to={{
                pathname: `/login`,
                state: { error: "Please Login to Access the Page" }
              }}
            />
          )}
        </Route>
        <Route path="*">
          <Errors />
        </Route>
      </Switch>
    </Router>
  );
};
export default App;
