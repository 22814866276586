import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormSelect,
  FormInput,
  Form
} from "shards-react";
import userService from "../../services/user.service";
import { ArticleContext } from "../../context/ArticleProvider";
import { UserContext } from "../../context/UserProvider";

const SidebarCategories = ({ title, editionList }) => {
  const { article, setArticle } = useContext(ArticleContext);
  const { user } = useContext(UserContext);
  const [sections, setSections] = useState([]);
  const [newSection, setNewSection] = useState("");
  const homePageDisplay = ["Do Not Display", "Featured", "Exclusive"];

  useEffect(() => {
    let unmounted = false;
    async function getData() {
      const sectionList = await userService.getSectionList();
      const editions = await userService.getEditionList();
      if (!unmounted) {
        setArticle({
          ...article,
          edition: editions.data[0].id,
          section: sectionList.data[0].id
        });
        setSections(
          sectionList.data.map(({ name, id }) => ({
            label: name,
            value: id
          }))
        );
      }
    }
    getData();
    return () => {
      unmounted = true;
    };
  }, []);

  const handleValueChange = e => {
    // console.log(e.target.value)
    setArticle({
      ...article,
      section: e.target.value
    });
  };

  const handleHomePageDisplay = e => {
    const value = e.target.value;
    if (value !== "Do Not Display")
      setArticle({
        ...article,
        home_page_display: value
      });
    else {
      setArticle({
        ...article,
        home_page_display: null
      });
    }
  };

  const handleEditionValue = e => {
    setArticle({
      ...article,
      edition: e.target.value
    });
  };

  const handleAddNewSection = e => {
    e.preventDefault();
    userService
      .addNewSection({
        name: newSection
      })
      .then(res => {
        const newItem = res.data;
        setNewSection("");
        setSections(prevValues => [
          ...prevValues,
          {
            value: newItem.id,
            label: newItem.name
          }
        ]);
      });
  };
  return (
    <Card small className="mb-3">
      <CardHeader className="border-bottom">
        <h6 className="m-0">{title}</h6>
      </CardHeader>
      <CardBody className="p-0">
        <ListGroup flush>
          <ListGroupItem className="px-3 pb-2">
            <InputGroup className="mb-3">            
              <FormSelect value={article.edition} onChange={handleEditionValue}>
                {editionList.map(({ label, value }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </FormSelect>
              <InputGroupAddon type="append">
                <InputGroupText>Select Edition</InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </ListGroupItem>

          <ListGroupItem className="px-3 pb-2">
            <InputGroup className="mb-3">
              <FormSelect value={article.section} onChange={handleValueChange}>
                {sections.map(({ label, value }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </FormSelect>
              <InputGroupAddon type="append">
                <InputGroupText>Select Category</InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </ListGroupItem>

          {user.isSuperUser && (
            <ListGroupItem className="px-3 pb-2">
              <InputGroup className="mb-3">
                <FormSelect
                  value={article.home_page_diplay}
                  onChange={handleHomePageDisplay}
                >
                  {homePageDisplay.map((item, index) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </FormSelect>
                <InputGroupAddon type="append">
                  <InputGroupText>Select Display</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </ListGroupItem>
          )}

          {user.isSuperUser && (
            <ListGroupItem className="d-flex px-3">
              <Form onSubmit={handleAddNewSection}>
                <InputGroup className="ml-auto">
                  <FormInput
                    placeholder="New category"
                    value={newSection}
                    onChange={event => setNewSection(event.target.value)}
                  />
                  <InputGroupAddon type="append"></InputGroupAddon>
                  <Button theme="white" className="px-2">
                    <i className="material-icons">add</i>
                  </Button>
                </InputGroup>
              </Form>
            </ListGroupItem>
          )}
        </ListGroup>
      </CardBody>
    </Card>
  );
};

SidebarCategories.propTypes = {
  title: PropTypes.string
};

SidebarCategories.defaultProps = {
  title: "Categories"
};

export default SidebarCategories;
