import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  Button,
  ListGroup,
  ListGroupItem,
  Row
} from "shards-react";
import styles from "./styles.modules.css";
import userService from "../../services/user.service";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import CircleSpinner from "../loaders/CircleSpinner";
import authService from "../../services/auth.service";

const UserDetails = ({ userDetails }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imageName, setImageName] = useState("");
  const [idProof, setIdProof] = useState();

  const prevImg = userDetails.image;
  const uploadedImage = useRef(null);
  const history = useHistory();

  const handleImageUpload = e => {
    const [file] = e.target.files;
    setImageName(file.name);
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = e => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const MenuToggle = () => {
    setShowMenu(!showMenu);
  };

  const cancelUpload = () => {
    MenuToggle();
    const { current } = uploadedImage;
    current.src = prevImg;
    setImageName("");
  };

  const saveNewProfileImage = e => {
    const img = uploadedImage.current.file;
    if (img == null || img === undefined) {
      toast.warn("Image is empty! Please select an image");
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("image", img, img.name);
    userService
      .updateUserImage(formData)
      .then(res => {
        return userService.getUserProfile();
      })
      .then(_ => {
        setLoading(false);
        toast.success("Photo Updated Successfully");
        setTimeout(() => {
          history.go(0);
        }, 2000);
      });
  };

  const handleIdProofChange = e => {
    const [file] = e.target.files;
    setIdProof(file);
  };

  const uploadIdProof = e => {
    const img = idProof;
    if (img == null || img === undefined) {
      toast.warn("Image is empty! Please select an image");
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("is_verified", true);
    formData.append("photo_id_proof", img, img.name);
    userService
      .updateUserImage(formData)
      .then(_ => {
        return userService.getUserProfile();
      })
      .then(_ => {
        setLoading(false);
        toast.success("Id Submitted For Verification Successfully");
        setTimeout(() => {
          history.go(0);
        }, 2000);
      });
    // setLoading(false)
  };

  return loading ? (
    <CircleSpinner />
  ) : (
    <Card small className="mb-4 pt-3">
      <CardHeader className="border-bottom text-center">
        <div className="mb-3 mx-auto">
          <img
            ref={uploadedImage}
            className="rounded-circle"
            src={
              userDetails.image
                ? userDetails.image
                : require("./../../images/avatars/0.jpg")
            }
            alt={userDetails.first_name}
            style={{
              width: "100px",
              height: "100px"
            }}
          />
          {!showMenu ? (
            <div className={styles.edit}>
              <Button outline size="sm" className="mt-2" onClick={MenuToggle}>
                <i className="material-icons mr-1">edit</i>Edit
              </Button>
            </div>
          ) : (
            <div>
              <ListGroup flush>
                <ListGroupItem className="d-flex px-3">
                  <div className="custom-file">
                    <input
                      type="file"
                      className="custom-file-input"
                      id="customFile2"
                      accept="image/*"
                      onChange={handleImageUpload}
                    />
                    <label className="custom-file-label" htmlFor="customFile2">
                      {imageName ? imageName : "Choose Image"}
                    </label>
                  </div>
                </ListGroupItem>
              </ListGroup>
              <Row className="mt-1">
                <Button
                  pill
                  outline
                  size="sm"
                  theme="danger"
                  className="mb-2 mx-auto"
                  onClick={cancelUpload}
                >
                  <i className="material-icons">close</i> Cancel
                </Button>
                <Button
                  pill
                  outline
                  size="sm"
                  className="mb-2 mx-auto"
                  onClick={saveNewProfileImage}
                >
                  <i className="material-icons">cloud_upload</i> Upload
                </Button>
              </Row>
            </div>
          )}
        </div>
        <h4 className="mb-0">{authService.getCurrentUser()}</h4>
        <Button pill outline size="sm" className="mb-2">
          <i className="material-icons mr-1">person</i> Author
        </Button>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="px-4">
          <div className="progress-wrapper">
            <strong className="text-muted d-block mb-2">Member Since</strong>
            <strong className="text d-block ">
              {Date(userDetails.date_joined)}
            </strong>
          </div>
        </ListGroupItem>
        <ListGroupItem className="px-4">
          <div className="progress-wrapper">
            <strong className="text-muted d-block">Articles Published</strong>
            <strong className="text d-block ">{"Number here"}</strong>
          </div>
        </ListGroupItem>
        <ListGroupItem className="px-4">
          <strong className="text-muted d-block mb-2">Bio</strong>
          <span>{userDetails.description || "Short Bio"}</span>
        </ListGroupItem>

        <ListGroupItem className="d-flex px-3">
          <strong className="text-muted d-block">
            Verification Status :{" "}
            {userDetails.is_verified ? <strong>Verified</strong> : <strong>Not Verified</strong>}
          </strong>
        </ListGroupItem>
        {!userDetails.is_verified && (
          <ListGroupItem>
            <span className="mb-2">Upload Id Proof</span>
            <div className="custom-file mt-1">
              <input
                type="file"
                className="custom-file-input"
                id="idProof"
                accept="image/*"
                onChange={handleIdProofChange}
              />
              <label className="custom-file-label" htmlFor="idProof">
                {idProof ? idProof.name : "Upload Id Proof"}
              </label>
            </div>
            <Button
              pill
              outline
              size="sm"
              className="mt-4 mx-auto"
              onClick={uploadIdProof}
            >
              <i className="material-icons">cloud_upload</i> Upload
            </Button>
          </ListGroupItem>
        )}
      </ListGroup>
    </Card>
  );
};

UserDetails.propTypes = {
  userDetails: PropTypes.object
};

export default UserDetails;
