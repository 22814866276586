import axios from "axios";
import authHeader from "./auth-header";

const API_URL = "https://api.kanzulhaya.com";

class UserService {
  getUserProfile() {
    return axios
      .get(API_URL + "/user-profile/", {
        headers: authHeader({ image: false })
      })
      .then(response => {
        if (!response.data.curr_author) {
          localStorage.clear("user");
        } else {
          localStorage.setItem(
            "user_details",
            JSON.stringify(response.data.curr_author)
          );
        }
      })
      .catch(err => console.log(err.message));
  }

  getSectionList() {
    return axios.get(API_URL + "/section-list", {
      headers: authHeader({ image: false })
    });
  }

  addNewSection(data) {
    return axios.post(API_URL + "/section-list", data, {
      headers: authHeader({ image: false })
    });
  }

  updateSection(sectionId, data) {
    return axios.put(API_URL + `/section-detail/${sectionId}/`, data, {
      headers: authHeader({ image: false })
    });
  }

  deleteSection(id) {
    return axios.delete(API_URL + "/section-detail/" + id, {
      headers: authHeader({ image: false })
    });
  }

  getEditionList() {
    return axios.get(API_URL + "/edition-list", {
      headers: authHeader({ image: false })
    });
  }

  addNewEdition(data) {
    return axios.post(API_URL + "/edition-list", data, {
      headers: authHeader({ image: true })
    });
  }

  updateEdition(editionSlug, data) {
    return axios.put(API_URL + `/edition-detail/${editionSlug}/`, data, {
      headers: authHeader({ image: false })
    });
  }

  deleteEdition(id) {
    return axios.delete(API_URL + "/edition-detail/" + id, {
      headers: authHeader({ image: false })
    });
  }

  addNewArticleImage(data) {
    return axios.post(API_URL + "/article-image-list/", data, {
      headers: authHeader({ image: true })
    });
  }

  getArticlesinEdition(id) {
    return axios.get(API_URL + `/article-list?edition=${id}`, {
      headers: authHeader({ image: false })
    });
  }

  getPosts() {
    return axios.get(API_URL + "/article-details-list", {
      headers: authHeader({ image: false })
    });
  }

  getAuthorArticles() {
    return axios.get(API_URL + "/all-author-articles/", {
      headers: authHeader({ image: false })
    });
  }

  getAuthorArticlesWithStatus(status) {
    return axios.get(
      API_URL + `/all-author-articles/?article_status=${status}`,
      {
        headers: authHeader({ image: false })
      }
    );
  }

  getSinglePost(id) {
    return axios.get(API_URL + `/article-detail/${id}/`, {
      headers: authHeader({ image: false })
    });
  }

  deletePost(id) {
    return axios.delete(API_URL + `/article-detail/${id}/`, {
      headers: authHeader({ image: false })
    });
  }

  async addNewPost(data) {
    try {
      const res = await axios.post(API_URL + "/create-article/", data, {
        headers: authHeader({ image: true })
      });
      return res;
    } catch (e) {
      console.log(e.message);
      throw new Error("Server Error");
    }
  }

  async saveEditedPost({ id, data }) {
    try {
      const res = await axios.put(API_URL + `/edit-article/${id}/`, data, {
        headers: authHeader({ image: false })
      });
      return res;
    } catch (e) {
      console.log(e.message);
      throw new Error("Server Error");
    }
  }

  getAuthorsList() {
    return axios.get(API_URL + "/writer-list", {
      headers: authHeader({ image: false })
    });
  }

  addNewAuthor(data) {
    return axios.post(API_URL + "/add-author/", data, {
      headers: authHeader({ image: false })
    });
  }

  updateWriterAccess(data) {
    return axios.get(API_URL + "/create-author-superuser/", data, {
      headers: authHeader({ image: false })
    });
  }

  changeArticleStatus(data) {
    return axios.post(API_URL + "/article-status-update/", data, {
      headers: authHeader({ image: false })
    });
  }

  updateUserProfile(data) {
    return axios.post(API_URL + "/user-profile/", data, {
      headers: authHeader({ image: false })
    });
  }

  updateUserImage(data) {
    return axios.post(API_URL + "/user-profile/", data, {
      headers: authHeader({ image: true })
    });
  }

  getCorouselList() {
    return axios.get(API_URL + "/corousel_list", {
      headers: authHeader({ image: false })
    });
  }

  addCorouselSlide(data) {
    return axios.post(API_URL + "/corousel_list", data, {
      headers: authHeader({ image: false })
    });
  }

  deleteCorouselSlide(id) {
    return axios.delete(API_URL + `/corousel_detail/${id}/`, {
      headers: authHeader({ image: false })
    });
  }
}

export default new UserService();
