/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, CardFooter } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import userService from "../services/user.service";

const ViewEditions = () => {
  const { slug } = useParams();
  const [articles, setArticles] = useState([]);
  useEffect(() => {
    let unmounted = false;
    async function getEditions() {
      const body = await userService.getArticlesinEdition(slug);
      if (!unmounted) {
        setArticles(
          body.data.map(article => ({
            slug: article.slug,
            id: article.id,
            name: article.name,
            image: article.image,
            description: article.description,
            section: article.section.name,
            writer: article.writer.name
          }))
        );
      }
    }
    getEditions();
    return () => {
      unmounted = true;
    };
  }, []);
  const MAX_LENGTH = 250;
  return (
    <Container>
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title={`Edition ${slug}`}
          subtitle="View All Previous Editions"
          className="text-sm-left"
        />
      </Row>
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title={`Total Articles - ${articles.length}`}
          subtitle=""
          className="text-sm-left"
        />
      </Row>

      <Row>
        {articles.map(article => (
          <Col lg="3" md="6" sm="12" className="mb-4" key={article.id}>
            <Card small className="card-post h-100">
              <div
                className="card-post__image"
                style={{ backgroundImage: `url('${article.image}')` }}
              />
              <CardBody>
                <h5 className="card-title">
                  <a
                    className="text-fiord-blue"
                    href={`/view-post/${article.slug}`}
                  >
                    {article.name}
                  </a>
                </h5>
                <p
                  className="card-text d-inline-block mb-3"
                  dangerouslySetInnerHTML={{
                    __html:
                      article.description.length > MAX_LENGTH
                        ? `${article.description.substring(0, MAX_LENGTH)}...`
                        : article.description
                  }}
                >
                </p>
              </CardBody>
              <CardFooter className="text-muted border-top py-3">
                <span className="d-inline-block">
                  By{" "}
                  <span className="text-fiord-blue">
                    {article.writer ? article.writer : "Name"}
                  </span>{" "}
                  in <span className="text-fiord-blue">{article.section}</span>
                </span>
              </CardFooter>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default ViewEditions;
