import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ArticleProvider } from "./context/ArticleProvider";
import { LoaderProvider } from "./context/LoaderProvider";
import { UserProvider } from "./context/UserProvider";

const loader = document.querySelector(".loader-container");
const showLoader = () => loader.classList.remove("loader--hide");
const hideLoader = () => loader.classList.add("loader--hide");

ReactDOM.render(
  <UserProvider>
    <ArticleProvider>
      <LoaderProvider>
        <App hideLoader={hideLoader} showLoader={showLoader} />
      </LoaderProvider>
    </ArticleProvider>
  </UserProvider>,
  document.getElementById("root")
);
// debugger;
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

