import React, { createContext, useState, useEffect } from "react";
import authService from "../services/auth.service";

const getLocalStorage = key => {
  if (localStorage["user"]) {
    return true
  }
  return false;
};
const UserContext = createContext({ name: "", auth: false, isSuperUser : false });

const UserProvider = ({ children }) => {
  // User is the name of the "data" that gets stored in context
  const [user, setUser] = useState({ name : "", auth: true , isSuperUser : false});


  useEffect(() => {
     setUser({
      auth: getLocalStorage("user"),
      name: authService.getCurrentUser(),
      isSuperUser : authService.getIsSuperUser(),
    });
  }, []);

  // Login updates the user data with a name parameter
  const login = status => {
    setUser({
      name: authService.getCurrentUser(),
      auth: true,
      isSuperUser : authService.getIsSuperUser(),
    });
  };

  // Logout updates the user data to default
  const logout = () => {
    setUser(user => ({
      name: "",
      auth: false
    }));
  };

  return (
    <UserContext.Provider value={{ user, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export { UserProvider, UserContext };
