import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { Container, Row, Col, Card, CardBody } from "shards-react";
import CircleSpinner from "../components/loaders/CircleSpinner";
import userService from "../services/user.service";
import "../assets/blog.css";
const ViewBlogPost = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [post, setPost] = useState({
    id: "slug",
    title: "Post Title",
    image: "Post Image URL",
    description: "Post Description",
    content: "Post Content",
    writer: "Pratik",
    date: "May 20, 2021",
    views: 0
  });

  useEffect(() => {
    let unmounted = false;
    setLoading(true);
    async function getSinglePost() {
      const body = await userService.getSinglePost(id);
      if (!unmounted) {
        const data = body.data;
        setPost({
          id: data.slug,
          title: data.name,
          image: data.banner_image,
          writer_image: data.writer.image,
          description: data.description,
          content: data.content,
          writers: data.writer.name,
          section: data.section.name,
          views: data.visits
        });
        setLoading(false);
      }
    }
    getSinglePost();
    return () => {
      unmounted = true;
    };
  }, []);
  const style = {
    borderRadius: "100%",
    border: "2px black solid",
    height: "100px",
    width: "100px"
  };
  return (
    <Container fluid className="main-content-container center px-4">
      {loading ? (
        <CircleSpinner />
      ) : (
        <Col sm="12" lg="10" className="mt-4 mx-auto mb-5">
          <Card small className="card-post card-post--1">
            <div
              className="card-post__image"
              style={{
                backgroundImage: `url('${post.image}')`
              }}
            ></div>
            <CardBody>
              <Row className=" border-bottom border-top border-dark d-flex">
                <img
                  className="my-2"
                  style={style}
                  alt="avatar"
                  src={`${post.writer_image}`}
                ></img>
                <Col className="my-auto mx-auto">
                  <span className="d-flex">
                    <strong className="mr-1 text-black font-weight-bold">
                      {post.writers}
                    </strong>
                  </span>
                  <span className="d-flex mb-2">
                    <strong className="mr-1">Section:</strong>{" "}
                    <strong className="text-success">{post.section}</strong>
                  </span>
                  <span className="d-flex mb-2">                    
                    <strong className="mr-2">Article Views:{" "}</strong>
                    <strong className="text">{post.views}</strong>
                  </span>
                </Col>
              </Row>
              <h2 className="mt-4">
                <p className="text-black">{post.title}</p>
              </h2>
              <h5 className="d-inline-block mb-3" dangerouslySetInnerHTML={{ __html: post.description }}></h5>
              <div
                className="blog_conent ql-editor"
                dangerouslySetInnerHTML={{ __html: post.content }}
              ></div>
            </CardBody>
          </Card>
        </Col>
      )}
    </Container>
  );
};

export default ViewBlogPost;
