import React from "react";
import { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Toast from "../components/toast/Toast";
import { Container } from "shards-react";
import LoginLoader from "../components/loaders/LoginLoader";
import { UserContext } from "../context/UserProvider";
import authService from "../services/auth.service";
import userService from "../services/user.service";

const Login = props => {
  const location = useLocation();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const { login } = useContext(UserContext);
  let history = useHistory();

  useEffect(() => {
    location.state && setError(location.state.error);
  }, []);

  const handleSubmit = e => {
    setLoading(true);
    e.preventDefault();
    if (!username || !password) {
      const msg = "Please Provide the Required Credentials";
      toast.error(msg);
      return;
    }
    setError("");
    authService
      .login(username, password)
      .then(() => {
        return userService.getUserProfile();
      })
      .then( _ => {
        login();
        history.replace("/");
        history.go(0)
      })
      .catch(err => {
        setLoading(false);
        const msg = err.message;
        if (msg) {
          toast.error(msg);
        } else toast.error("Some Error Occured");
      });
  };
  return loading ? (
    <LoginLoader />
  ) : (
    <Container fluid className="main-content-container px-4 h-100">
      <Toast />
      <main className="main-content col col pt-5">
        <div className="main-content-container h-100 px-4 container-fluid">
          <div className="h-100 no-gutters row">
            <div className="auth-form mx-auto my-auto col-md-5 col-lg-6">
              <div className="card">
                <img
                  src={process.env.PUBLIC_URL + "/logo.png"}
                  alt="logo"
                  className="mx-auto my-3"
                  style={{ height: "60px", width: "60px" }}
                />
                <div className="card-body">
                  <h5 className="auth-form__title text-center mb-4">
                    Login to Your Account
                  </h5>
                  <form className="" onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label htmlFor="exampleInputEmail1">Username</label>
                      <input
                        type="text"
                        id="exampleInputEmail1"
                        placeholder="Enter username or Email"
                        className="form-control"
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                      ></input>
                    </div>
                    <div className="form-group">
                      <label htmlFor="exampleInputPassword1">Password</label>
                      <input
                        type="password"
                        id="exampleInputPassword1"
                        placeholder="Password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        autoComplete="current-password"
                        className="form-control"
                      ></input>
                    </div>
                    <button
                      type="submit"
                      className="d-table mx-auto btn btn-accent btn-pill"
                    >
                      Log In
                    </button>
                  </form>
                </div>
              </div>
              <div className="auth-form__meta d-flex mt-4">
                <a href="/forgot-password">Forgot your password?</a>
              </div>
              {error && (
                <div className="bg-danger text-white text-center d-flex  mt-4 card align-center">
                  <p className="card-heading mt-2 font-weight-bold">{error}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </main>
    </Container>
  );
};

export default Login;
