import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  FormInput,
  FormTextarea,
  Button
} from "shards-react";
import { toast } from "react-toastify";
import userService from "../../services/user.service";
import CircleSpinner from "../loaders/CircleSpinner";
import { useHistory } from "react-router-dom";

const UserAccountDetails = ({ userDetails, title }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState({
    firstname: "",
    lastname: "",
    email: "",
    username: "",
    linkedin: "",
    description: ""
  });
  useEffect(() => {
    const data = userDetails;
    setDetails({
      firstname: data.user.first_name,
      lastname: data.user.last_name,
      email: data.user.email,
      username: data.user.username,
      linkedin: data.linkedin,
      description: data.description
    });
  }, []);

  const handleChange = e => {
    let { name } = e.target;
    setDetails({
      ...details,
      [name]: e.target.value
    });
  };
  const updateProfile = e => {
    setLoading(true);
    e.preventDefault();
    const data = {
      first_name: details.firstname,
      last_name: details.lastname,
      email: details.email,
      username: details.username,
      linkedin: details.linkedin,
      description: details.description
    };
    userService
      .updateUserProfile(data)
      .then(() => {
        return userService.getUserProfile();
      })
      .then(_ => {
        setLoading(false);
        toast.success("Profile Updated Sucessfully");
        setTimeout(() => {
          history.go(0);
        }, 3000);
      });
  };

  return loading ? (
    <CircleSpinner />
  ) : (
    <Card small className="mb-4">
      <CardHeader className="border-bottom">
        <h6 className="m-0">{title}</h6>
      </CardHeader>
      <ListGroup flush>
        <ListGroupItem className="p-3">
          <Row>
            <Col>
              <Form onSubmit={updateProfile}>
                <Row form>
                  <Col md="6" className="form-group">
                    <label htmlFor="FirstName">First Name</label>
                    <FormInput
                      id="FirstName"
                      placeholder="First Name"
                      value={details.firstname}
                      name="firstname"
                      onChange={e => handleChange(e)}
                    />
                  </Col>
                  <Col md="6" className="form-group">
                    <label htmlFor="LastName">Last Name</label>
                    <FormInput
                      id="LastName"
                      placeholder="Last Name"
                      value={details.lastname}
                      name="lastname"
                      onChange={e => handleChange(e)}
                    />
                  </Col>
                </Row>
                <FormGroup>
                  <label htmlFor="email">Email</label>
                  <FormInput
                    type="email"
                    id="email"
                    placeholder="Email"
                    value={details.email}
                    name="email"
                    onChange={e => handleChange(e)}
                  />
                </FormGroup>
                <Row form>
                  <Col md="6" className="form-group">
                    <label htmlFor="username">Username</label>
                    <FormInput
                      id="username"
                      placeholder="Username"
                      value={details.username}
                      name="username"
                      onChange={e => handleChange(e)}
                    />
                  </Col>
                  <Col md="6" className="form-group">
                    <label htmlFor="linkedin">LinkedIn</label>
                    <FormInput
                      id="linkedin"
                      placeholder="https://www.linkedin.com/<username>"
                      value={details.linkedin || ""}
                      name="linkedin"
                      onChange={e => handleChange(e)}
                    />
                  </Col>
                </Row>
                <Row form>
                  <Col md="12" className="form-group">
                    <label htmlFor="Description">Your Bio</label>
                    <FormTextarea
                      id="Description"
                      rows="5"
                      value={details.description}
                      name="description"
                      onChange={e => handleChange(e)}
                    />
                  </Col>
                </Row>
                <Button theme="accent">Update Account</Button>
              </Form>
            </Col>
          </Row>
        </ListGroupItem>
      </ListGroup>
    </Card>
  );
};
UserAccountDetails.propTypes = {
  /**
   * The component's title.
   */
  title: PropTypes.string
};

UserAccountDetails.defaultProps = {
  title: "Account Details"
};

export default UserAccountDetails;
