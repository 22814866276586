import React, { useContext } from "react";
import { Container, Row, Col } from "shards-react";
import PageTitle from "../components/common/PageTitle";
import Editor from "../components/add-new-post/Editor";
import SidebarActions from "../components/add-new-post/SidebarActions";
import SidebarCategories from "../components/add-new-post/SidebarCategories";
import SidebarImageUpload from "../components/add-new-post/SidebarImageUpload";
import { useParams } from "react-router-dom";
import Toast from "../components/toast/Toast";
import { LoaderContext } from "../context/LoaderProvider";
import CircleSpinner from "../components/loaders/CircleSpinner";
import { useEffect } from "react";
import userService from "../services/user.service";
import { useState } from "react";
import { ArticleContext } from "../context/ArticleProvider";

const AddNewPost = ({ edit }) => {
  const { loading } = useContext(LoaderContext);
  const { id } = useParams();
  const [editions, setEditions] = useState([]);
  const { setArticle, setContent } = useContext(ArticleContext);
  useEffect(() => {
    let unmounted = false;
    let articleDetails;
    async function getData() {
      if (id) {
        articleDetails = await userService.getSinglePost(id);
      }
      const editionList = await userService.getEditionList();
      if (!unmounted) {
        setEditions(
          editionList.data.map(({ name, id }) => ({
            label: name,
            value: id
          }))
        );
        if (id) {
          const post = articleDetails.data;
          console.log(post.section.id)
          setArticle({
            edition: post.edition,
            name: post.name,
            status: post.status,
            description: post.description,
            section: post.section.id,
            home_page_display : post.home_page_display,
          });
          setContent(post.content);
        }
      }
    }
    getData();
    return () => {
      unmounted = true;
    };
  }, []);

  const title = edit ? "Edit Your Article" : "Write a New Article";
  return (
    <Container fluid className="main-content-container px-4 pb-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title={title}
          subtitle="Let your imagination run free"
          className="text-sm-left"
        />
      </Row>
      <Toast />
      {loading ? (
        <CircleSpinner />
      ) : (
        <Row>
          {/* Editor */}
          <Col lg="9" md="12">
            <Editor postId={id} />
          </Col>

          {/* Sidebar Widgets */}
          <Col lg="3" md="12">
            <SidebarActions edit={edit} id={id} />
            <SidebarCategories editionList={editions} />
            {!edit && <SidebarImageUpload />}
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default AddNewPost;
