import React, { Component } from "react";
import styles from "./spinners.module.css";

class CircleSpinner extends Component {
  render() {
    return (
      <div className={styles.parent}>
        <div className={styles.spinner}></div>
      </div>
    );
  }
}

export default CircleSpinner;
