import React, { useContext, useMemo } from "react";
import ReactQuill from "react-quill";
import { Card, CardBody, FormInput, Row } from "shards-react";
import PageTitle from "../../components/common/PageTitle";

import "react-quill/dist/quill.snow.css";
import "../../assets/quill.css";
import { ArticleContext } from "../../context/ArticleProvider";
import userService from "../../services/user.service";
import placeholder from "../../images/placeholder.jpg";

const Editor = () => {
  const { article, setArticle, content, setContent } = useContext(
    ArticleContext
  );
  const quillRef = React.useRef(null);

  const handleTitleChange = e => {
    e.preventDefault();
    setArticle({
      ...article,
      name: e.target.value
    });
  };

  const handleDescriptionChange = e => {
    setArticle({
      ...article,
      description: e.target.value
    });
  };

  const handleContentChange = content => {
    setContent(content);
  };

  const articleimageHandler = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");

    input.click();
    input.onchange = async () => {
      var img = input.files[0];
      const name = new Date().getTime().toString();
      var formData = new FormData();
      formData.append("name", name);
      formData.append("image", img);
      const range = quillRef.current.getEditorSelection();

      quillRef.current
        .getEditor()
        .insertEmbed(range.index, "image", `${placeholder}`);

      quillRef.current.getEditor().setSelection(range.index + 1);
      userService.addNewArticleImage(formData).then(_ => {
        quillRef.current.getEditor().deleteText(range.index, 1);
        quillRef.current
          .getEditor()
          .insertEmbed(
            range.index,
            "image",
            "https://api.kanzulhaya.com/article-image-detail/" + name
          );
        quillRef.current.getEditor().setSelection(range.index + 1);
      });
    };
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        clipboard: {
          matchVisual: false
        },
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
           [{ 'font': [] }],
          ["bold", "italic", "underline", "strike"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ align: [] }],
          ["link", "image"],
          [{ 'color': [] }],  
          [{ script: "sub" }, { script: "super" }],
          [{ indent: "-1" }, { indent: "+1" }],
          [{ 'direction': 'rtl' }],
        ],
        handlers: {
          image: articleimageHandler
        }
      }
    }),
    []
  );

  return (
    <div>
      <Card small className="mb-3">
        <CardBody>
          <div className="add-new-post">
            <FormInput
              size="lg"
              className="mb-3"
              placeholder="Your Article Title"
              onChange={handleTitleChange}
              value={article.name}
            />
            <FormInput
              size="lg"
              className="mb-3"
              placeholder="Your Article Sub Title"
              onChange={handleDescriptionChange}
              value={article.description}
            />
            <ReactQuill
              ref={quillRef}
              modules={modules}
              className="add-new-post__editor mb-1"
              onChange={handleContentChange}
              value={content}
            />
          </div>
        </CardBody>
      </Card>

      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Preview Your Article"
          subtitle="See how your Article looks on the web"
          className="text-sm-left"
        />
      </Row>
      <Card small className="mb-3">
        <CardBody>
          <h2 className="mt-4">
            <p className="text-black">{article.name}</p>
          </h2>
          <h5 className="d-inline-block mb-3" dangerouslySetInnerHTML={{ __html: article.description }}></h5>
          <div
            className="preview mb-1 ql-editor"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
        </CardBody>
      </Card>
    </div>
  );
};

export default Editor;
