import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardHeader, CardBody } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import CircleSpinner from "../components/loaders/CircleSpinner";
import userService from "../services/user.service";

const Authors = () => {
  const [authors, setAuthors] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let unmounted = false;
    setLoading(true);
    async function getPosts() {
      const body = await userService.getAuthorsList();
      if (!unmounted) {
        setAuthors(
          body.data.map((author, index) => ({
            name: `${author.user.first_name} ${author.user.last_name}`,
            description: author.description,
            is_superuser: author.user.is_superuser,
            email: author.user.email,
            image : author.image
          }))
        );
        setLoading(false)
      }
    }
    getPosts();
    return () => {
      unmounted = true;
    };
  }, []);
  return (
    <Container fluid className="main-content-container px-4">
      {/* Page Header */}
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Authors"
          subtitle="All Registered Authors"
          className="text-sm-left"
        />
      </Row>

      {/* Default Light Table */}
      {loading ? <CircleSpinner/> : 
      <Row>
        <Col>
          <Card small className="mb-4">
            <CardHeader className="border-bottom">
              <h6 className="m-0">Active Authors</h6>
            </CardHeader>
            <CardBody className="p-0 pb-3">
              <table className="table mb-0">
                <thead className="bg-light">
                  <tr>
                    <th scope="col" className="border-0">
                      #
                    </th>
                    <th scope="col" className="border-0">
                      Profile Photo
                    </th>
                    <th scope="col" className="border-0">
                      Full Name
                    </th>
                    <th scope="col" className="border-0">
                      Email
                    </th>
                    <th scope="col" className="border-0">
                      Admin Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {authors.map((author, idx) => (
                    <tr key={idx}>
                      <td>{idx + 1}</td>
                      <td
                        className="profile_img"                        
                      ><img src={author.image} alt="ProfilePhoto" style={{borderRadius : "100%",  width : "30px", height : "30px"}}></img></td>
                      <td>{author.name}</td>
                      <td>{author.email}</td>
                      {author.is_superuser ? <td>Admin</td> : 
                       <td>Writer</td>
                      }
                    </tr>
                  ))}
                </tbody>
              </table>
            </CardBody>
          </Card>
        </Col>
      </Row>
      }
    </Container>
  );
};

export default Authors;
