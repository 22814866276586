import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Container,
  Row,
  Card,
  Col,
  CardBody,
  Button,
  Form,
  FormInput,
  ListGroup,
  ListGroupItem
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import CircleSpinner from "../components/loaders/CircleSpinner";
import Toast from "../components/toast/Toast";
import userService from "../services/user.service";

const EditHomePageSettings = () => {
  const [text, setText] = useState("");
  const [image, setImage] = useState();
  const [slides, setSlides] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    let unmounted = false;
    setLoading(true);
    async function getSlides() {
      const body = await userService.getCorouselList();
      if (!unmounted) {
        setSlides(
          body.data.map(slide => ({
            id: slide.id,
            text: slide.text,
            image: slide.image
          }))
        );
        setLoading(false);
      }
    }
    getSlides();
    return () => {
      unmounted = true;
    };
  }, []);

  const handleImageUpload = e => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = e => {
    setLoading(true);
    e.preventDefault();
    // if (text === "" || text === null) {
    //   setLoading(false);
    //   toast.error("Empty Name! Please fill the fields");
    //   return;
    // }
    if (image === undefined || image === null) {
      setLoading(false);
      toast.error("Empty Image! Please select an image");
      return;
    }
    const formData = new FormData();
    formData.append("text", text);
    formData.append("image", image, image.name);

    userService
      .addCorouselSlide(formData)
      .then(res => {
        setLoading(false);
        toast.success("Slide Added");
        setTimeout(() => {
          history.go(0);
        }, 3000);
      })
      .catch(err => {
        setLoading(false);
        toast.error("Some Error Occured");
      });
  };

  const deleteSlide = id => {
    userService.deleteCorouselSlide(id).then(_ => {
      toast.success("Deleted Slide");
      setTimeout(() => {
        history.go(0);
      }, 3000);
    });
  };

  return (
    <div>
      <Container fluid className="main-content-container px-4">
        <Toast />
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="Add a New Slide"
            subtitle="Create a New Slide"
            className="text-sm-left"
          />
        </Row>
        {loading ? (
          <CircleSpinner />
        ) : (
          <Card small className="mb-3">
            <CardBody>
              <Form className="add-new-post" onSubmit={handleSubmit}>
                <FormInput
                  size="lg"
                  className="mb-3"
                  placeholder="Slide Text"
                  value={text}
                  onChange={event => setText(event.target.value)}
                />
                <ListGroup flush>
                  <ListGroupItem className="d-flex">
                    <div className="custom-file mb-3 px-0">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="customFile2"
                        accept="image/*"
                        onChange={handleImageUpload}
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="customFile2"
                      >
                        {image ? image.name : "Choose image ..."}
                      </label>
                    </div>
                  </ListGroupItem>
                </ListGroup>
                <Button>Add Slide</Button>
              </Form>
            </CardBody>
          </Card>
        )}

        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="HomePage Carousel"
            subtitle="Home Page Slider Slides List"
            className="text-sm-left"
          />
        </Row>
        {loading ? (
          <CircleSpinner />
        ) : (
          <Row>
            {slides.map((slide, idx) => (
              <Col lg="6" sm="12" className="mb-4" key={idx}>
                <Card>
                  <div
                    className="card-post__image"
                    style={{
                      backgroundImage: `url(${slide.image})`
                    }}
                  ></div>
                  <CardBody>
                    <h5 className="card-title">{slide.text}</h5>
                    <Button
                      theme="danger"
                      onClick={() => deleteSlide(slide.id)}
                    >
                      Delete
                    </Button>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        )}
      </Container>
    </div>
  );
};

export default EditHomePageSettings;
