import React from "react";
import { Link, useHistory } from "react-router-dom";
import { useState, useContext } from "react";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Collapse,
  NavItem,
  NavLink
} from "shards-react";
import authService from "../../../../services/auth.service";
import { UserContext } from "../../../../context/UserProvider";

const UserActions = () => {
  const { logout, user } = useContext(UserContext);
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const handleLogout = () => {
    authService.logout().then(() => {
      logout();
      history.replace("/login");
    });
  };
  const userImage = authService.getCurrentUserDetails().image;

  return (
    <NavItem tag={Dropdown} caret toggle={() => setVisible(!visible)}>
      <DropdownToggle
        caret
        tag={NavLink}
        className="text-nowrap px-3"
        style={{ cursor: "pointer" }}
      >
        <img
          className="user-avatar rounded-circle mr-2"
          src={userImage || "/0.jpg"}
          style={{
            maxHeight: "40px",
          }}
          alt="User Avatar"
        />{" "}
        <span className="d-none  d-md-inline-block">{user.name}</span>
      </DropdownToggle>
      <Collapse tag={DropdownMenu} right small open={visible}>
        <DropdownItem tag={Link} to="/user-profile">
          <i className="material-icons">&#xE7FD;</i> Profile
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem tag={Link} to="/change-password">
          <i className="material-icons">pin</i> Change Password
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem onClick={handleLogout} className="text-danger">
          <i className="material-icons text-danger">&#xE879;</i> Logout
        </DropdownItem>
      </Collapse>
    </NavItem>
  );
};

export default UserActions;
