/* eslint jsx-a11y/anchor-is-valid: 0 */

import React, { useContext } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  ListGroup,
  ListGroupItem,
  Button
} from "shards-react";

import { ArticleContext } from "../../context/ArticleProvider";
import userService from "../../services/user.service";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { LoaderContext } from "../../context/LoaderProvider";
const SidebarActions = ({ edit, id, postId }) => {
  const { article, setArticle, content, setContent } = useContext(
    ArticleContext
  );
  const { setLoading } = useContext(LoaderContext);
  const history = useHistory();

  const handlePublish = ({ isRequestToPublish }) => {
    setLoading(true);
    const formData = new FormData();
    for (let [key, value] of Object.entries(article)) {
      if (key === "status" || key === "home_page_display") continue;
      if (value === "" || value === null || value === undefined) {
        setLoading(false);
        toast.error(`Article ${key} is Empty`);
        return;
      }
      if (key === "image" || key === "background_image") {
        formData.append(key, value, value.name);
      } else formData.append(key, value);
    }
    isRequestToPublish
      ? formData.append("status", "Draft")
      : formData.append("status", "Created");
    if (content === "" || content === null || content === undefined) {
      setLoading(false);
      toast.error(`Article Content is Empty`);
      return;
    }
    formData.append("content", content);
    userService
      .addNewPost(formData)
      .then(res => {
        if (res === undefined || res === "undefined") {
          setLoading(false);
          toast.error("Server Error Occured");
        }
        setArticle({
          edition: "",
          name: "",
          description: "",
          image: null,
          section: 1
        });
        setContent("");
        setLoading(false);
        isRequestToPublish
          ? toast.success("Requested for Review")
          : toast.success("Created New Article");
        setTimeout(() => {
          history.push("/home");
        }, 5000);
      })
      .catch(err => {
        setLoading(false);
        toast.error(err.message);
      });
  };

  const handleEdit = ({ isRequestToPublish }) => {
    setLoading(true);
    const formData = new FormData();
    for (let [key, value] of Object.entries(article)) {
      console.log(key, value)
      if (key === "image" || key === "banner_image") continue;

      if (key === "home_page_display" && value === null) {
        value = "";
        continue;
      }

      if (key === "status") {
        if (isRequestToPublish) value = "Draft";
      }

      if (value === "" || value === undefined) {
        setLoading(false);
        toast.error(`Article ${key} is Empty`);
        return;
      }
      formData.append(key, value);
    }
    formData.append("content", content);

    userService.saveEditedPost({ id: id, data: formData }).then(res => {
      setArticle({
        edition: "",
        name: "",
        description: "",
        image: null,
        section: 1
      });
      setContent("");
      setLoading(false);
      isRequestToPublish
        ? toast.success("Requested for Review")
        : toast.success("Successfully Edited Article");
      setTimeout(() => {
        history.push("/home");
      }, 5000);
    });
  };

  return (
    <Card small className="mb-3">
      <CardHeader className="border-bottom">
        <h6 className="m-0">Actions</h6>
      </CardHeader>
      <CardBody className="p-0">
        <ListGroup flush>
          <ListGroupItem className="p-3">
            <span className="d-flex mb-2">
              <i className="material-icons mr-1">flag</i>
              <strong className="mr-1">Status:</strong> Draft{" "}
            </span>
            <span className="d-flex mb-2">
              <i className="material-icons mr-1">visibility</i>
              <strong className="mr-1">Visibility:</strong>{" "}
              <strong className="text-success">Public</strong>{" "}
            </span>
            <span className="d-flex">
              <i className="material-icons mr-1">score</i>
              <strong className="mr-1">Readability:</strong>{" "}
              <strong className="text-warning">Ok</strong>
            </span>
          </ListGroupItem>
          {!edit ? (
            <ListGroupItem className="d-flex px-3 border-0">
              <Button
                onClick={() => handlePublish({ isRequestToPublish: true })}
                outline
                theme="accent"
                size="sm"
                className="mb-2 mr-2"
              >
                <i className="material-icons">file_copy</i> Request for Publish
              </Button>
              <Button
                onClick={() => handlePublish({ isRequestToPublish: false })}
                theme="accent"
                size="sm"
                className="mb-2 ml-auto"
              >
                <i className="material-icons">save</i> Save New Article
              </Button>
            </ListGroupItem>
          ) : (
            <ListGroupItem className="d-flex px-3 border-0">
              <Button
                onClick={() => handleEdit({ isRequestToPublish: true })}
                theme="accent"
                size="sm"
                className="ml-2"
              >
                <i className="material-icons">file_copy</i> Request for Publish
              </Button>
              <Button
                onClick={() => handleEdit({ isRequestToPublish: false })}
                theme="accent"
                size="sm"
                className="ml-auto"
              >
                <i className="material-icons">library_add</i> Save Edit
              </Button>
            </ListGroupItem>
          )}
        </ListGroup>
      </CardBody>
    </Card>
  );
};

SidebarActions.propTypes = {
  title: PropTypes.string
};

SidebarActions.defaultProps = {
  title: "Actions"
};

export default SidebarActions;
