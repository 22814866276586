import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Card,
  Container,
  Row,
  CardBody,
  Form,
  FormInput,
  Button,
  ListGroupItem,
  FormSelect,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormTextarea
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import CircleSpinner from "../components/loaders/CircleSpinner";
import Toast from "../components/toast/Toast";
import userService from "../services/user.service";

const EditionsEdit = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [editions, setEditions] = useState([]);
  const [editionSlug, setEditionSlug] = useState();
  const history = useHistory();

  useEffect(() => {
    let unmounted = false;
    async function getData() {
      const editionList = await userService.getEditionList();
      if (!unmounted) {
        setEditions(
          editionList.data.map(({ name, id, slug, description }) => ({
            name: name,
            id: id,
            slug: slug,
            description: description
          })) 
        );
        setEditionSlug(editionList.data[0].slug);
        setName(editionList.data[0].name);
        setDescription(editionList.data[0].description);
      }
    }
    getData();
    return () => {
      unmounted = true;
    };
  }, []);

  const handleSubmit = e => {
    setLoading(true);
    e.preventDefault();
    if (name === "" || name === null) {
      setLoading(false);
      toast.error("Empty Name! Please fill the fields");
      return;
    }
    if (description === "" || description === null) {
      setLoading(false);
      toast.error("Empty Description! Please fill the fields");
      return;
    }
    const data = { name: name, description: description };
    userService
      .updateEdition(editionSlug, data)
      .then(res => {
        setLoading(false);
        toast.success("Edition Updated");
        setTimeout(() => {
          history.go(0);
        }, 2000);
      })
      .catch(err => {
        setLoading(false);
        toast.error("Some Error Occured");
      });
  };
  const handleValueChange = e => {
    var result = editions.filter(obj => {
      return obj.slug === e.target.value;
    });
    setName(result[0].name)
    setDescription(result[0].description)
    setEditionSlug(result[0].slug);
  };

  const deleteSection = e => {
    userService
      .deleteEdition(editionSlug)
      .then(res => {
        setLoading(false);
        toast.success("Editon Deleted");
        setTimeout(() => {
          history.go(0);
        }, 2000);
      })
      .catch(err => {
        setLoading(false);
        toast.error("Some Error Occured");
      });
  };

  return (
    <div>
      <Container fluid className="main-content-container px-4">
        <Toast />
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="Edit Editions Here"
            subtitle="Update Editions"
            className="text-sm-left"
          />
        </Row>
        {loading ? (
          <CircleSpinner />
        ) : (
          <Card small className="mb-3">
            <CardBody>
              <ListGroupItem className="px-3 pb-2">
                <InputGroup className="mb-3">
                  <FormSelect value={editionSlug} onChange={handleValueChange}>
                    {editions.map(edition => (
                      <option key={edition.id} value={edition.slug}>
                        {edition.name}
                      </option>
                    ))}
                  </FormSelect>
                  <InputGroupAddon type="append">
                    <InputGroupText>Select Category</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </ListGroupItem>
              <Form className="add-new-post" onSubmit={handleSubmit}>
                <FormInput
                  size="lg"
                  className="mb-3"
                  placeholder="New Edition Name"
                  value={name}
                  onChange={event => setName(event.target.value)}
                />
                <FormTextarea
                  size="lg"
                  className="mb-3"
                  placeholder="New Edition Description"
                  value={description}
                  onChange={event => setDescription(event.target.value)}
                />
                <Button>Update Edition</Button>
              </Form>
              <Button className="mt-4" theme="danger" onClick={deleteSection}>
                Delete Edition
              </Button>
            </CardBody>
          </Card>
        )}
      </Container>
    </div>
  );
};

export default EditionsEdit;
