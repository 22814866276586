import authService from "../services/auth.service";

const SideNavItems = () => {
  const isSuperUser = authService.getIsSuperUser();
  const sideItems = [
    {
      title: "Home",
      htmlBefore: '<i class="material-icons">vertical_split</i>',
      to: "/home",
      onlySuperUser: false
    },
    {
      title: "Add New Article",
      htmlBefore: '<i class="material-icons">post_add</i>',
      to: "/add-new-article",
      onlySuperUser: false
    },
    {
      title: "User Profile",
      htmlBefore: '<i class="material-icons">person</i>',
      to: "/user-profile",
      onlySuperUser: false
    },
    {
      title: "All Editions",
      htmlBefore: '<i class="material-icons">book</i>',
      to: "/editions",
      onlySuperUser: false
    },
    {
      title: "My Articles",
      htmlBefore: '<i class="material-icons">library_books</i>',
      to: "/my-articles",
      onlySuperUser: false
    },
    {
      title: "Add Edition",
      htmlBefore: '<i class="material-icons">note_add</i>',
      to: "/add-edition",
      onlySuperUser: true
    },
    {
      title: "Edit Editions",
      htmlBefore: '<i class="material-icons">tune</i>',
      to: "/edit-editions",
      onlySuperUser: true
    },
    {
      title: "All Authors",
      htmlBefore: '<i class="material-icons">groups</i>',
      to: "/authors",
      onlySuperUser: true
    },
    {
      title: "Add Author",
      htmlBefore: '<i class="material-icons">person_add</i>',
      to: "/add-author",
      onlySuperUser: true
    },
    {
      title: "Manage Authors",
      htmlBefore: '<i class="material-icons">manage_accounts</i>',
      to: "/manage-authors",
      onlySuperUser: true
    },
    {
      title: "Articles Status",
      htmlBefore: '<i class="material-icons">format_list_bulleted</i>',
      to: "/article-status",
      onlySuperUser: true
    },
    {
      title: "Sections Edit",
      htmlBefore: '<i class="material-icons">tune</i>',
      to: "/sections-edit",
      onlySuperUser: true
    },
    {
      title: "Home Page Settings",
      htmlBefore: '<i class="material-icons">settings_applications</i>',
      to: "/edit-home-page-settings",
      onlySuperUser: true
    }
  ];

  var finalSideNavItems = [];
  sideItems.forEach(item => {
    if (item.onlySuperUser === true && isSuperUser)
      finalSideNavItems.push(item);
    if (item.onlySuperUser === false) finalSideNavItems.push(item);
  });

  return finalSideNavItems;
};

export default SideNavItems;
