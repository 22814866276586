import React, { useState } from "react";
import {  toast } from "react-toastify";
import {
  Card,
  Container,
  Row,
  CardBody,
  Form,
  FormInput,
  ListGroup,
  ListGroupItem,
  FormTextarea,
  Button
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import CircleSpinner from "../components/loaders/CircleSpinner";
import Toast from "../components/toast/Toast";
import userService from "../services/user.service";

const AddNewEdition = () => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState();
  const [loading, setLoading] = useState(false);

  const handleImageUpload = e => {
    setImage(e.target.files[0]);
  };

  const handleSubmit = e => {
    setLoading(true);
    e.preventDefault();
    if(name === "" || name === null){
      setLoading(false)
      toast.error("Empty Name! Please fill the fields")
      return
    }
    if(description === "" || description === null){
      setLoading(false)
      toast.error("Empty Description! Please fill the fields")
      return
    }
    if(image === undefined || image === null){
      setLoading(false)
      toast.error("Empty Image! Please select an image")
      return
    }
    const formData = new FormData();
    formData.append("name", name);
    formData.append("description", description);
    formData.append("image", image, image.name);

    userService.addNewEdition(formData).then(res => {
      setLoading(false);
      toast.success("Edition Added");
    }).catch(err  => {
      setLoading(false)
      toast.error("Some Error Occured");
    })
  };

  return (
    <div>
      <Container fluid className="main-content-container px-4">
        <Toast />
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="Add a New Edition"
            subtitle="Create a New Edition"
            className="text-sm-left"
          />
        </Row>
        {loading ? (
          <CircleSpinner />
        ) : (
          <Card small className="mb-3">
            <CardBody>
              <Form className="add-new-post" onSubmit={handleSubmit}>
                <FormInput
                  size="lg"
                  className="mb-3"
                  placeholder="Edition Name"
                  value={name}
                  onChange={event => setName(event.target.value)}
                />
                <FormTextarea
                  size="lg"
                  className="mb-3"
                  placeholder="Description for Edition"
                  value={description}
                  onChange={event => setDescription(event.target.value)}
                />
                <ListGroup flush>
                  <ListGroupItem className="d-flex">
                    <div className="custom-file mb-3 px-0">
                      <input
                        type="file"
                        className="custom-file-input"
                        id="customFile2"
                        accept="image/*"
                        onChange={handleImageUpload}
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="customFile2"
                      >
                        {image ? image.name : "Choose image ..."}
                      </label>
                    </div>
                  </ListGroupItem>
                </ListGroup>
                <Button>Add Edition</Button>
              </Form>
            </CardBody>
          </Card>
        )}
      </Container>
    </div>
  );
};

export default AddNewEdition;
