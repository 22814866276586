import React, { useEffect, useState } from "react";
import userService from "../services/user.service";
import Table from "../components/article-status/Table";
import CircleSpinner from "../components/loaders/CircleSpinner";
import { useHistory } from "react-router-dom";
import classes from "../components/article-status/article-status.module.css"
const ArticleStatus = () => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  useEffect(() => {
    let unmounted = false;
    setLoading(true);
    async function getPosts() {
      const body = await userService.getPosts();
      if (!unmounted) {
        const draft = body.data.all_draft_articles.map(article => ({
          id: article.id,
          name: article.name,
          status: article.status,
          slug: article.slug
        }));

        const created = body.data.all_created_articles.map(article => ({
          id: article.id,
          name: article.name,
          status: article.status,
          slug: article.slug
        }));

        const rejected = body.data.all_rejected_articles.map(article => ({
          id: article.id,
          name: article.name,
          status: article.status,
          slug: article.slug
        }));

        var Articles = [];
        Articles.push(...draft);
        Articles.push(...created);
        Articles.push(...rejected);
        setArticles(Articles);
        setLoading(false);
      }
    }
    getPosts();
    return () => {
      unmounted = true;
    };
  }, []);

  const columns = React.useMemo(() => [
    {
      width : 600,
      Header: "Name",
      accessor: "name"
    },
    {
      Header: "Status",
      accessor: "status"
    },
    {
      Header: "View",
      accessor: "slug",
      Cell: ({ cell }) => (
        <button className={classes.iconButton} onClick={() => history.push( `/view-post/${cell.row.values.slug}`)}>
          <i class="material-icons">visibility</i>
        </button>
      )
    },
    {
      Header: "Edit",
      accessor: "id",
      Cell: ({ cell }) => (
        <button className={classes.iconButton} onClick={() => history.push( `/edit-article/${cell.row.values.slug}`)}>
          <i class="material-icons">edit</i>
        </button>
      )
    }
  ]);

  const data = articles;
  return loading ? <CircleSpinner /> : <Table columns={columns} data={data} />;
};

export default ArticleStatus;
