import React, { createContext, useState } from "react";

const LoaderContext = createContext({loading : false});

const LoaderProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  return (
    <LoaderContext.Provider value={{loading, setLoading}}>
      {children}
    </LoaderContext.Provider>
  );
};

export { LoaderContext, LoaderProvider };
