import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts";

// Route Views
import BlogPosts from "./views/BlogPosts";
import UserProfile from "./views/UserProfileLite";
import AddNewPost from "./views/AddNewPost";
import ViewBlogPost from "./views/ViewBlogPost";
import Editions from "./views/Editions";
import Authors from "./views/AuthorsTable";
import AddNewEdition from "./views/AddNewEdition";
import AddNewAuthor from "./views/AddAuthors";
import ViewEditions from "./views/ViewEdition";
import ArticleStatus from "./views/ArticleStatus";
import MyArticles from "./views/MyArticles";
import SectionsEdit from "./views/SectionsEdit";
import EditHomePageSettings from "./views/EditHomePageSettings";
import EditionsEdit from "./views/EditionsEdit";
import ManageAuthors from "./views/ManageAuthors";

const routes = [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/home" />
  },
  {
    path: "/user-profile",
    exact: true,
    layout: DefaultLayout,
    component: UserProfile
  },
  {
    path: "/add-new-article",
    exact: true,
    layout: DefaultLayout,
    component: () => AddNewPost({ edit: false })
  },
  {
    path: "/editions",
    exact: true,
    layout: DefaultLayout,
    component: Editions
  },
  {
    path: "/view-edition/:slug",
    exact: true,
    layout: DefaultLayout,
    component: ViewEditions
  },
  {
    path: "/add-edition",
    exact: true,
    layout: DefaultLayout,
    component: AddNewEdition
  },
  {
    path: "/authors",
    exact: true,
    layout: DefaultLayout,
    component: Authors
  },
  {
    path: "/add-author",
    exact: true,
    layout: DefaultLayout,
    component: AddNewAuthor
  },
  {
    path: "/home",
    exact: true,
    layout: DefaultLayout,
    component: BlogPosts
  },
  {
    path: "/view-post/:id",
    exact: true,
    layout: DefaultLayout,
    component: ViewBlogPost
  },
  {
    path: "/edit-article/:id",
    exact: true,
    layout: DefaultLayout,
    component: () => AddNewPost({ edit: true })
  },
  {
    path: "/article-status",
    exact: true,
    layout: DefaultLayout,
    component: ArticleStatus
  },
  {
    path: "/my-articles",
    exact: true,
    layout: DefaultLayout,
    component: MyArticles
  },
  {
    path: "/sections-edit",
    exact: true,
    layout: DefaultLayout,
    component: SectionsEdit
  },
  {
    path: "/edit-home-page-settings",
    exact: true,
    layout: DefaultLayout,
    component: EditHomePageSettings
  },
  {
    path: "/edit-editions",
    exact: true,
    layout: DefaultLayout,
    component: EditionsEdit
  },
  {
    path: "/manage-authors",
    exact: true,
    layout: DefaultLayout,
    component: ManageAuthors
  }
];

export default routes;
