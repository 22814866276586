import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody } from "shards-react";

import PageTitle from "../components/common/PageTitle";
import CircleSpinner from "../components/loaders/CircleSpinner";
import userService from "../services/user.service";

const Editions = () => {
  const [editions, setEditions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    let unmounted = false;
    setLoading(true);
    async function getEditions() {
      const body = await userService.getEditionList();
      if (!unmounted) {
        setEditions(
          body.data.map(edition => ({
            id: edition.slug,
            title: edition.name,
            image: edition.image,
            description: edition.description,
            date: "May 20, 2021"
          }))
        );
        setLoading(false);
      }
    }
    getEditions();
    return () => {
      unmounted = true;
    };
  }, []);
  const MAX_COUNT = 250;
  return (
    <Container>
      <Row noGutters className="page-header py-4">
        <PageTitle
          sm="4"
          title="Editions"
          subtitle="View All Previous Editions"
          className="text-sm-left"
        />
      </Row>

      {loading ? (
        <CircleSpinner />
      ) : (
        <Row>
          {editions.map((edition, idx) => (
            <Col lg="3" md="6" sm="12" className="mb-4" key={idx}>
              <Card small className="card-post card-post--1">
                <div
                  className="card-post__image"
                  style={{
                    backgroundImage: `url(${edition.image})`
                  }}
                ></div>
                <CardBody>
                  <h5 className="card-title">
                    <a
                      href={`/view-edition/${edition.id}`}
                      className="text-fiord-blue"
                    >
                      {edition.title}
                    </a>
                  </h5>
                  <p className="card-text d-inline-block mb-3">
                    {edition.description.length > MAX_COUNT ? `${edition.description.substring(0, MAX_COUNT)}...` : edition.description}
                  </p>
                  <br></br>
                  <span className="text-muted">
                    Published on {edition.date}
                  </span>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      )}
    </Container>
  );
};

export default Editions;
