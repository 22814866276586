import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  Card,
  Container,
  Row,
  CardBody,
  Form,
  FormInput,
  FormTextarea,
  Button
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import CircleSpinner from "../components/loaders/CircleSpinner";
import Toast from "../components/toast/Toast";
import userService from "../services/user.service";

const AddNewAuthor = () => {
  const [loading, setLoading] = useState(false);
  const [newAuthor, setNewAuthor] = useState({
    username: "",
    first_name: "",
    last_name: "",
    email: "",
    description: "",
    linkedin: "",
    city : "",
    address: "",
    contact: ""
  });

  const handleSubmit = e => {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData();
    for (let [key, value] of Object.entries(newAuthor)) {
      formData.append(key, value);
    }
    userService
      .addNewAuthor(formData)
      .then(res => {
        setNewAuthor({
          username: "",
          first_name: "",
          last_name: "",
          email: "",
          description: "",
          linkedin: ""
        });
        setLoading(false);
        toast.info("Author Added to Database");
      })
      .catch(err => {
        toast.warn(err.message);
      });
  };

  return (
    <div>
      <Container fluid className="main-content-container px-4">
        <Toast />
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="Add a Author"
            subtitle="Add a New Author to the Magazine"
            className="text-sm-left"
          />
        </Row>
        {loading ? (
          <CircleSpinner />
        ) : (
          <Card small className="mb-3">
            <CardBody>
              <Form className="add-new-post" onSubmit={handleSubmit}>
                <FormInput
                  size="lg"
                  className="mb-3"
                  placeholder="Author's User Name"
                  value={newAuthor.username}
                  onChange={event =>
                    setNewAuthor({ ...newAuthor, username: event.target.value })
                  }
                />
                <FormInput
                  size="lg"
                  className="mb-3"
                  placeholder="Author's First Name"
                  value={newAuthor.first_name}
                  onChange={event =>
                    setNewAuthor({
                      ...newAuthor,
                      first_name: event.target.value
                    })
                  }
                />
                <FormInput
                  size="lg"
                  className="mb-3"
                  placeholder="Author's Last Name"
                  value={newAuthor.last_name}
                  onChange={event =>
                    setNewAuthor({
                      ...newAuthor,
                      last_name: event.target.value
                    })
                  }
                />
                <FormInput
                  size="lg"
                  className="mb-3"
                  placeholder="Author's Email"
                  value={newAuthor.email}
                  onChange={event =>
                    setNewAuthor({ ...newAuthor, email: event.target.value })
                  }
                />
                <FormInput
                  size="lg"
                  className="mb-3"
                  placeholder="Author's Contact Number"
                  value={newAuthor.contact}
                  onChange={event =>
                    setNewAuthor({ ...newAuthor, contact: event.target.value })
                  }
                />
                <FormInput
                  size="lg"
                  className="mb-3"
                  placeholder="Author's Linkedin"
                  value={newAuthor.linkedin}
                  onChange={event =>
                    setNewAuthor({
                      ...newAuthor,
                      linkedin: event.target.value
                    })
                  }
                />
                <FormInput
                  size="lg"
                  className="mb-3"
                  placeholder="Author's City"
                  value={newAuthor.city}
                  onChange={event =>
                    setNewAuthor({
                      ...newAuthor,
                      city: event.target.value
                    })
                  }
                />
                <FormTextarea
                  size="lg"
                  className="mb-3"
                  placeholder="Author's Address"
                  value={newAuthor.address}
                  onChange={event =>
                    setNewAuthor({
                      ...newAuthor,
                      address: event.target.value
                    })
                  }
                />
                <FormTextarea
                  size="lg"
                  className="mb-3"
                  placeholder="Author's Short Bio"
                  value={newAuthor.description}
                  onChange={event =>
                    setNewAuthor({
                      ...newAuthor,
                      description: event.target.value
                    })
                  }
                />
                <Button> Add Author</Button>
              </Form>
            </CardBody>
          </Card>
        )}
      </Container>
    </div>
  );
};

export default AddNewAuthor;
