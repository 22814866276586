import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Card,
  Container,
  Row,
  CardBody,
  Button,
  ListGroupItem,
  FormSelect,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "shards-react";
import PageTitle from "../components/common/PageTitle";
import CircleSpinner from "../components/loaders/CircleSpinner";
import Toast from "../components/toast/Toast";
import userService from "../services/user.service";

const ManageAuthors = () => {
  const [writer, setWriter] = useState({ name: "name", status: true });
  const [loading, setLoading] = useState(false);
  const [writers, setWriters] = useState([]);
  const [writerSlug, setWriterSlug] = useState();
  const history = useHistory();

  useEffect(() => {
    let unmounted = false;
    async function getData() {
      const writerList = await userService.getAuthorsList();
      if (!unmounted) {
        setWriters(
          writerList.data.map(({ name, id, slug, user }) => ({
            name: name,
            id: id,
            slug: slug,
            is_superuser: user.is_superuser
          }))
        );
        setWriterSlug(writerList.data[0].id);
        setWriter({
          name: writerList.data[0].name,
          status: writerList.data[0].user.is_superuser
        });
      }
    }
    getData();
    return () => {
      unmounted = true;
    };
  }, []);

  const handleAccessChange = e => {
    setLoading(true);
    e.preventDefault();
    const data = {writer_slug : writerSlug}
    userService
      .updateWriterAccess(data)
      .then(res => {
        setLoading(false);
        toast.success("Updated Access");
        setTimeout(() => {
          history.go(0);
        }, 2000);
      })
      .catch(err => {
        setLoading(false);
        toast.error("Some Error Occured");
      });
  };
  const handleValueChange = e => {
    var result = writers.filter(obj => {
      return obj.slug === e.target.value;
    });
    setWriter({name : result[0].name, status : result[0].is_superuser });
    setWriterSlug(result[0].slug);
  };

  const deleteAuthor = e => {
    toast.success("Author Removed");
    setTimeout(() => {
      history.go(0);
    }, 2000);
  };

  return (
    <div>
      <Container fluid className="main-content-container px-4">
        <Toast />
        <Row noGutters className="page-header py-4">
          <PageTitle
            sm="4"
            title="Set Authors Powers Here"
            subtitle="Manage Authors Here"
            className="text-sm-left"
          />
        </Row>
        {loading ? (
          <CircleSpinner />
        ) : (
          <Card small className="mb-3">
            <CardBody>
              <ListGroupItem className="px-3 pb-2">
                <InputGroup className="mb-3">
                  <FormSelect value={writerSlug} onChange={handleValueChange}>
                    {writers.map(edition => (
                      <option key={edition.id} value={edition.slug}>
                        {edition.name}
                      </option>
                    ))}
                  </FormSelect>
                  <InputGroupAddon type="append">
                    <InputGroupText>Select Category</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </ListGroupItem>

              <div className="mt-5">
                <h4>Name : {writer.name}</h4>
                <h4>Status : {writer.status ? "Admin" : "Writer"}</h4>
              </div>
              <Button onClick={handleAccessChange}>Give Admin Access</Button>

              <Button className="ml-4" theme="danger" onClick={deleteAuthor}>
                Delete Author
              </Button>
            </CardBody>
          </Card>
        )}
      </Container>
    </div>
  );
};

export default ManageAuthors;
